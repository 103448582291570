import React from 'react';
import Link from 'next/link';
import dayjs from 'dayjs';
import { Hit, SearchResponse } from '@algolia/client-search';
import { DotIcon, Table } from '@spa-cars/ui';
import { useLazyQuery } from '@apollo/client';
import { validateString } from 'avilatek-utils';
import { PaginationInfo, User } from '@spa-cars/models';
import { useAlgolia } from '../../hooks';
import { GET_USERS_LIST } from '../../graphql/queries';

const buildTableData = (clients: Array<User | Hit<User>> = []) =>
  clients.map((client) => ({
    _id: client._id ?? (client as any).objectID,
    name: `${client?.firstName ?? ''} ${client?.lastName ?? ''}` ?? 'N/A',
    email: client?.email?.toLowerCase() ?? 'N/A',
    createdAt: dayjs(client?.createdAt).format('DD/MM/YYYY') ?? 'N/A',
    status: (
      <p className="flex gap-[10px] items-center">
        <DotIcon
          className={` ${
            client?.active ? 'text-primary-300' : ' text-danger-200'
          } w-[15px] h-[15px]`}
        />
        {client?.active ? 'Activo' : 'Baneado'}
      </p>
    ),
  }));

interface PaginationDataProps {
  items: Array<User>;
  count: number;
  pageInfo: PaginationInfo;
}

export default function ClientTable() {
  const searchClient = useAlgolia();
  const searchIndex = searchClient.initIndex('users');
  const [searchResult, setSearchResult] = React.useState<Hit<User>[]>(null);
  const [paginationData, setPaginationData] = React.useState<
    PaginationDataProps | SearchResponse<User>
  >(null);
  const [perPage, setPerPage] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState(''); // guarda lo que el usuario ingresa en el input de busqueda
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [loadClients, { data, loading, error, fetchMore }] = useLazyQuery<{
    userPagination: {
      items: Array<User>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(GET_USERS_LIST);

  const queryVariables = {
    perPage: perPage ?? 10,
    filter: {
      userType: 'client',
    },
    sort: 'CREATEDAT_DESC',
  };

  const handleGoToPage = (value) => {
    if (validateString(searchInput)) {
      searchClients(searchInput, value - 1); // ir a la siguiente pagina de la busqueda de algolia
    } else {
      loadClients({
        variables: {
          page: value ?? 1,
          ...queryVariables,
        },
        fetchPolicy: 'cache-and-network',
      }); // ir a la siguiente pagina del query
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <p className="flex justify-between items-center">
            NOMBRE
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'name',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            CORREO ELECTRÓNICO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'email',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            INGRESO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'createdAt',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            ESTATUS
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'status',
      },
    ],
    []
  );

  const filterResults = React.useCallback(
    (results: SearchResponse<User>) => results?.hits ?? [],
    []
  );

  const searchClients = async (value: string, page_: number) => {
    try {
      if (!validateString(value) || value === '') {
        loadClients({
          variables: {
            page: 1,
            ...queryVariables,
          },
          fetchPolicy: 'cache-and-network',
        });
        setPaginationData(data?.userPagination);
        setSearchResult([]);
        return;
      }
      setLoadingSearch(true);
      const result = await searchIndex.search<User>(value, {
        filters: 'userType:client',
        page: page_,
        hitsPerPage: perPage ?? 10,
      });
      setLoadingSearch(false);
      setPaginationData(result);
      const resultsFiltered = filterResults(result);
      setSearchResult(resultsFiltered);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    },
    []
  );

  const dataMemo = React.useMemo(
    () =>
      searchResult?.length > 0
        ? buildTableData(searchResult)
        : buildTableData((paginationData as PaginationDataProps)?.items) ?? [],
    [paginationData, searchResult]
  );

  React.useEffect(() => {
    if (data) {
      setPaginationData(data?.userPagination);
    }
  }, [data]);

  React.useEffect(() => {
    searchClients(searchInput, 0); // para algolia, la paginación empieza en 0
  }, [searchInput, perPage]);

  return (
    <Table
      columns={columns}
      perPage={perPage}
      setPerPage={setPerPage}
      data={dataMemo}
      error={null}
      loading={loading || loadingSearch}
      pageCount={0}
      header="Clientes"
      pageNumber={
        ((paginationData as SearchResponse<User>)?.page ??
          ((paginationData as PaginationDataProps)?.pageInfo?.currentPage ??
            1) - 1) + 1
      }
      addButtonText="Cliente"
      handleGoToPage={handleGoToPage}
      results={
        (paginationData as SearchResponse<User>)?.nbHits ??
        (paginationData as PaginationDataProps)?.count
      }
      path="app/clients"
      link={Link}
      onChangeSearch={onChangeSearch}
    />
  );
}
